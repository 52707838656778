import { clsx } from "clsx";
import type { ReactNode } from "react";

import HowToUseImage from "../../assets/icons/how_to_use.svg";
import CashbackImage from "../../assets/images/CashBack.png";
import DietitianApprovedImage from "../../assets/images/DietitianApproved.png";
import PeachIconBlue from "../../assets/images/PeachIconBlue.png";
import RemindImage from "../../assets/images/Remind.png";
import logoImage from "../../assets/images/SIconWhite72.png";
import SwapBadgeImage from "../../assets/images/Swapbadge.png";
import WelcomeImage from "../../assets/images/Welcome.png";
import { LayoutAppBar, LayoutAppBarSession } from "../LayoutAppBar";
import { LayoutFooter } from "../LayoutFooter";

export type LayoutAuthenticationSessionProps = LayoutAppBarSession;

export type LayoutAuthenticationProps = LayoutAuthenticationSessionProps & {
  readonly children?: ReactNode;

  /**
   * Node to display under the title.
   */
  readonly headerBody?: ReactNode;

  /**
   * An optional image to render in the header.
   */
  readonly headerImage?:
    | "cashback"
    | "dietitianApproved"
    | "howToUse"
    | "peach"
    | "remind"
    | "swapBadge"
    | "welcome";

  /**
   * The title to display.
   *
   * A different title for the mobile breakpoint can optionally be provided
   * using `headerTitleMobile`.
   */
  readonly headerTitle?: ReactNode;

  /**
   * The title to display when viewed within the mobile breakpoint.
   *
   * If not provided, falls back to value given for `headerTitle`.
   */
  readonly headerTitleMobile?: ReactNode;
};

const headerImageMetaMap: Readonly<
  Record<
    NonNullable<LayoutAuthenticationProps["headerImage"]>,
    {
      readonly alt: string;
      readonly height: number;
      readonly src: string;
      readonly width: number;
    }
  >
> = {
  cashback: {
    alt: "cartoon hand holding cash",
    height: 457,
    src: CashbackImage,
    width: 573,
  },
  dietitianApproved: {
    alt: "cartoon stethoscope",
    height: 416,
    src: DietitianApprovedImage,
    width: 442,
  },
  howToUse: {
    alt: "cartoon bag of groceries",
    height: 456,
    src: HowToUseImage,
    width: 456,
  },
  peach: {
    alt: "cartoon peach fruit with a smiley face",
    height: 337,
    src: PeachIconBlue,
    width: 427,
  },
  remind: {
    alt: "cartoon lady, thumbing up",
    height: 513,
    src: RemindImage,
    width: 513,
  },
  swapBadge: {
    alt: "cartoon bowl of ingredients",
    height: 765,
    src: SwapBadgeImage,
    width: 874,
  },
  welcome: {
    alt: "clock",
    height: 339,
    src: WelcomeImage,
    width: 434,
  },
};

export function LayoutAuthentication({
  children,
  headerBody,
  headerImage,
  headerTitle: headerTitleTablet,
  headerTitleMobile,
  ...session
}: LayoutAuthenticationProps) {
  const className = clsx(
    "grid grid-cols-1 md:grid-cols-[repeat(2,50%)] auto-rows-auto",
    session.authenticationStatus === "unauthenticated"
      ? "md:grid-rows-[minmax(100vh,auto)]"
      : "grid-rows-[3.5rem] md:grid-rows-[6rem,minmax(calc(100vh-6rem),auto)] lg:grid-rows-[8rem,minmax(calc(100vh-8rem),auto)]",
  );

  const asideClassName = clsx(
    "md:row-start-1 flex md:flex-col justify-between md:justify-start items-center md:items-start gap-4 p-4 md:p-8 lg:p-14 text-white bg-snap-blue",
    // TODO: Remove this CSS override once Ant Design is removed. It explicitly
    // sets the font color of headers to black and interferes with color
    // inheritance.
    "[&>*>*]:text-white",
    session.authenticationStatus === "unauthenticated" ? "" : "md:row-span-2",
  );

  const asideBottomClassName = clsx(
    "col-start-1 hidden md:block bg-snap-blue",
    session.authenticationStatus === "unauthenticated" ? "" : "row-start-3",
  );

  const headerTitleMobileClassName = clsx(
    headerTitleMobile && !headerTitleTablet
      ? // Always display this mobile title if no title for the tablet
        // breakpoint was provided.
        ""
      : headerTitleMobile
      ? // If both the mobile and tablet titles were provided, hide the mobile
        // title on the tablet breakpoint.
        "md:hidden"
      : // If not provided, don't display it.
        "hidden",
  );

  const headerTitleTabletClassName = clsx(
    headerTitleTablet && !headerTitleMobile
      ? // Always display this title if no mobile specific title was provided.
        ""
      : headerTitleTablet
      ? // If a mobile title was provided, only display this title on the tablet
        // breakpoint.
        "hidden md:block"
      : // If not provided, don't display it.
        "hidden",
  );

  const mainClassName = clsx(
    "md:row-span-2 p-4 mx-auto w-full max-w-7xl",
    session.authenticationStatus === "unauthenticated"
      ? "md:row-start-1 md:p-8          lg:p-14"
      : "md:row-start-2 md:px-8 md:pb-8 lg:px-14 lg:pb-14",
  );

  const headerImageMeta = headerImage ? headerImageMetaMap[headerImage] : null;

  return (
    <div className={className}>
      {session.authenticationStatus !== "unauthenticated" && (
        <LayoutAppBar className="md:col-start-2 fixed md:static" {...session} />
      )}

      {/* App bar spacer. */}
      <div className="md:hidden" />

      <aside className={asideClassName}>
        <img
          alt="swap logo"
          className="hidden md:block pb-8 lg:pb-14 xl:pb-[4.5rem]"
          height={72}
          src={logoImage}
          width={72}
        />

        {(headerBody || headerTitleMobile || headerTitleTablet) && (
          <div className="space-y-1 md:space-y-2">
            {(headerTitleTablet || headerTitleMobile) && (
              <h1 className="text-lg md:text-4xl lg:text-5xl font-extrabold md:leading-tight lg:leading-tight md:font-bold">
                <span className={headerTitleMobileClassName}>
                  {headerTitleMobile}
                </span>
                <span className={headerTitleTabletClassName}>
                  {headerTitleTablet}
                </span>
              </h1>
            )}
            {headerBody && (
              <p className="text-xs md:text-lg font-medium">{headerBody}</p>
            )}
          </div>
        )}
        {headerImageMeta && (
          // Wrap the image in a div with a padding set to the image aspect
          // ratio as a percentage. Using this trick, the image will always have
          // the correct aspect ratio, regardless of its width.
          <div className="md:w-full md:h-full md:max-w-[25rem]">
            <div
              className="relative md:top-[30%] w-24 md:w-full md:translate-y-[-30%]"
              style={{
                paddingTop: `${
                  (headerImageMeta.height / headerImageMeta.width) * 100
                }%`,
              }}
            >
              <img
                alt={headerImageMeta.alt}
                className="absolute top-0"
                height={headerImageMeta.height}
                src={headerImageMeta.src}
                width={headerImageMeta.width}
              />
            </div>
          </div>
        )}
      </aside>

      {/* Left blue border that extends past 100vh on tablet. */}
      <div className={asideBottomClassName} />

      <main className={mainClassName}>{children}</main>

      <LayoutFooter
        onSignOutClick={
          session.authenticationStatus !== "unauthenticated"
            ? session.onSignOutClick
            : null
        }
      />
    </div>
  );
}
