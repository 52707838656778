import { forwardRef, ReactNode, useId } from "react";

import { ErrorMessage } from "../ErrorMessage";
import { FieldLabel } from "../FieldLabel";
import { type InputProps, Input } from "../Input";

export type InputFieldProps = Omit<
  InputProps,
  "aria-errormessage" | "aria-invalid" | "error"
> & {
  /**
   * If provided; and not `undefined`, `null`, or empty; the error message will
   * be displayed.
   *
   * @default undefined
   */
  readonly error?: ReactNode;

  /**
   * The label for the input.
   */
  readonly label: ReactNode;

  /**
   * The class names for the label.
   */
  readonly labelClassName?: string;
};

/**
 * An input component combining `Label`, `Input`, and `ErrorMessage`.
 */
export const InputField = forwardRef<HTMLInputElement, InputFieldProps>(
  function InputField(
    { className, disabled, error, label, labelClassName, ...rest },
    ref,
  ) {
    const id = useId();
    const errorMessageId = `${id}-errorMessage`;

    return (
      <div className={className}>
        <FieldLabel
          disabled={disabled}
          error={!!error}
          className={labelClassName}
        >
          {label}
          <Input
            aria-errormessage={error ? errorMessageId : undefined}
            aria-invalid={!!error || undefined}
            disabled={disabled}
            error={!!error}
            ref={ref}
            {...rest}
          />
        </FieldLabel>
        <ErrorMessage id={errorMessageId}>{error}</ErrorMessage>
      </div>
    );
  },
);
