import { clsx } from "clsx";

/**
 * Composes the CSS class to apply to the label element.
 *
 * It is based on a combination of the branding overrides, input state
 * (disabled/error), and the consumer provided className prop.
 */
export function useLabelClassName(
  /**
   * Whether or not the input is disabled.
   */
  disabled: boolean,

  /**
   * Whether or not the input is in an error state.
   */
  error: boolean,

  /**
   * The classname supplied by the input component consumer.
   */
  classNameProp: string | undefined,
) {
  return clsx(
    "text-sm font-montserrat",
    disabled && "text-black/70",
    error && "text-snap-red",
    classNameProp,
  );
}
