import type { RouteComponentProps } from "@reach/router";
import React from "react";

import { SessionLoader } from "../session";
import { SignInPage } from "../sign-in";
import { assertDefined } from "../utils";

export default function LoginPageLoader({ location }: RouteComponentProps) {
  assertDefined(location);

  return (
    <SessionLoader audience="unauthenticated" location={location}>
      <SignInPage />
    </SessionLoader>
  );
}
