import { clsx } from "clsx";
import { forwardRef, PropsWithoutRef } from "react";
import { BsFacebook } from "react-icons/bs";
import { FcGoogle } from "react-icons/fc";

import type { ButtonProps } from "../Button";
import { useButtonBaseClassName } from "../ButtonBase";

export type SocialSignInButtonProps = Omit<ButtonProps, "type"> & {
  readonly provider: "facebook" | "google";
};

export const SocialSignInButton = forwardRef<
  HTMLButtonElement,
  PropsWithoutRef<SocialSignInButtonProps>
>(function SocialSignInButton(
  { children, className: classNameProp, disabled, provider, ...rest },
  ref,
) {
  const className = useButtonBaseClassName({
    backgroundColorClassName:
      provider === "facebook" ? "bg-snap-blue-matte" : "bg-snap-blue",
    backgroundColorDisabledClassName:
      provider === "facebook" ? "bg-snap-blue-matte/70" : "bg-snap-blue/70",
    classNameProp: clsx("flex justify-center items-center", classNameProp),
    disabled: disabled || false,
    textColorClassName: "text-white",
  });

  const Icon = provider === "facebook" ? BsFacebook : FcGoogle;

  return (
    <button
      className={className}
      disabled={disabled}
      ref={ref}
      type="button"
      {...rest}
    >
      <Icon className="mr-4 w-5 h-5" />
      {children}
    </button>
  );
});
