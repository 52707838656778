import { forwardRef, PropsWithoutRef, RefAttributes } from "react";

import { useButtonClassName } from "./useButtonClassName";

export type ButtonProps = Omit<
  PropsWithoutRef<JSX.IntrinsicElements["button"]>,
  "type"
> &
  Required<Pick<JSX.IntrinsicElements["button"], "type">> &
  RefAttributes<HTMLButtonElement>;

export const Button = forwardRef<
  HTMLButtonElement,
  PropsWithoutRef<ButtonProps>
>(function Button({ className: classNameProp, disabled, ...rest }, ref) {
  const className = useButtonClassName({
    classNameProp: classNameProp || "",
    disabled: disabled || false,
  });

  return (
    <button className={className} disabled={disabled} ref={ref} {...rest} />
  );
});
