import { clsx } from "clsx";
import { type GatsbyLinkProps, Link } from "gatsby";
import type { PropsWithoutRef } from "react";

export type TextLinkProps =
  | ({
      readonly type: "button";
    } & Omit<PropsWithoutRef<JSX.IntrinsicElements["button"]>, "type">)
  | (({
      readonly type: "linkExternal";
    } & Omit<PropsWithoutRef<JSX.IntrinsicElements["a"]>, "rel" | "target">) & {
      readonly disabled?: boolean | undefined;
    })
  | ({
      readonly type: "linkInternal";
    } & PropsWithoutRef<GatsbyLinkProps<unknown>> & {
        readonly disabled?: boolean | undefined;
      });

/**
 * A text link which can behave like a button, a link to an external site,
 * or a link to an internal page.
 *
 * Additionally, all versions can accept a "disabled" prop which will render
 * the link as disabled. This is suitable for form submissions.
 *
 * The underlying element rendered and the supported props are determined by the
 * `type` prop:
 *
 * - `button` renders a button element and accepts the normal `<button>` props.
 *    The exception is the `type` prop which is always set to `button`.
 * - `linkExternal` renders an `<a>` element and accepts the normal `<a>` props.
 * - `linkInternal` renders a Gatsby `<Link>` component and accepts the normal
 *   `<Link>` props.
 */
export function TextLink({
  className: classNameProp,
  disabled,
  ...rest
}: TextLinkProps): JSX.Element {
  const className = clsx(
    "text-left decoration-2 underline-offset-2 outline-none focus:ring-2 ring-snap-blue",
    disabled ? "text-black" : "text-snap-blue hover:underline",
    classNameProp,
  );

  switch (rest.type) {
    case "button": {
      const { type, ...restType } = rest;

      return (
        <button
          className={className}
          disabled={disabled}
          type="button"
          {...restType}
        />
      );
    }

    case "linkExternal": {
      const { type, ...restType } = rest;

      if (disabled) return <span className={className}>{rest.children}</span>;

      return (
        // eslint-disable-next-line jsx-a11y/anchor-has-content
        <a
          className={className}
          rel="noopener noreferrer"
          target="_blank"
          {...restType}
        />
      );
    }

    case "linkInternal": {
      const { type, ...restType } = rest;

      if (disabled) return <span className={className}>{rest.children}</span>;

      return <Link className={className} {...restType} />;
    }
  }
}
