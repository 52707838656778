import { clsx } from "clsx";

export type UseButtonBaseClassNameOptions = {
  /**
   * The CSS class passed by the consuming component, and optionally composed
   * with custom styles.
   */
  classNameProp: string;

  /**
   * The CSS class to apply for the background color when the button is not
   * disabled.
   */
  backgroundColorClassName: string;

  /**
   * The CSS class to apply for the background color when the button is
   * disabled.
   */
  backgroundColorDisabledClassName: string;

  /**
   * Whether or not the button is disabled.
   */
  disabled: boolean;

  /**
   * The CSS class to apply for the text color.
   */
  textColorClassName: string;
};

/**
 * Creates the base styles for a button.
 *
 * The `backgroundColorClassName`, `backgroundColorDisabledClassName`, and
 * `textColorClassName` are used to theme the button.
 */
export function useButtonBaseClassName({
  backgroundColorClassName,
  backgroundColorDisabledClassName,
  classNameProp,
  disabled,
  textColorClassName,
}: UseButtonBaseClassNameOptions) {
  return clsx(
    "px-6 py-3 md:py-[0.875rem] text-sm md:text-base font-bold tracking-wide rounded-l-full rounded-r-full transition-[filter] outline-none focus:ring-2 ring-offset-2 ring-snap-blue",
    textColorClassName,
    !disabled && "hover:saturate-50",
    disabled ? backgroundColorDisabledClassName : backgroundColorClassName,
    classNameProp,
  );
}
