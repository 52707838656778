import { clsx } from "clsx";
import type { PropsWithoutRef } from "react";

export type ErrorMessageProps = PropsWithoutRef<JSX.IntrinsicElements["span"]>;

export function ErrorMessage({
  children,
  className: classNameProp,
  ...rest
}: ErrorMessageProps) {
  const className = clsx(
    // Maintain a minimum height to prevent the form from shifting when
    // validation errors appear.
    "block min-h-[1rem] text-xs text-snap-red",
    !children && "invisible",
    classNameProp,
  );

  return (
    <span className={className} {...rest}>
      {children}
    </span>
  );
}
