import { useAddAlert } from "../alert";
import { sessionSignIn, sessionSignInWithGoogle } from "../session";
import { useAppDispatch } from "../store";
import { handleError } from "../utils";
import { SignInFormValues, SignInPageContents } from "./SignInPageContents";

export function SignInPage() {
  const dispatch = useAppDispatch();
  const addAlert = useAddAlert();

  const handleSignInWithFacebookClick = async () => {
    try {
      await dispatch(sessionSignInWithGoogle()).unwrap();
    } catch (error) {
      handleError(addAlert, error);
    }
  };

  const handleSignInWithGoogleClick = async () => {
    try {
      await dispatch(sessionSignInWithGoogle()).unwrap();
    } catch (error) {
      handleError(addAlert, error);
    }
  };

  const handleSubmit = async (fields: SignInFormValues) => {
    try {
      await dispatch(sessionSignIn(fields)).unwrap();
    } catch (error) {
      handleError(addAlert, error);
    }
  };

  return (
    <SignInPageContents
      onSignInWithFacebookClick={handleSignInWithFacebookClick}
      onSignInWithGoogleClick={handleSignInWithGoogleClick}
      onSubmit={handleSubmit}
    />
  );
}
