import { clsx } from "clsx";

export type DividerProps = {
  readonly children?: string;
  readonly className?: string;
};

/**
 * A horizontal divider, which serves the same purpose as an <hr> element. It
 * supports an optional text label.
 */
export function Divider({ className: classNameProp, children }: DividerProps) {
  const className = clsx(
    "flex items-center my-4 font-medium before:flex-1 before:border-t after:flex-1 after:border-t",
    classNameProp,
  );

  return (
    <div className={className} role="separator">
      {children && <span className="px-4">{children}</span>}
    </div>
  );
}
