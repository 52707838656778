import type { AddAlertFn } from "../alert";

/**
 * An error with a similar shape to those thrown by the Firebase client SDK or
 * custom application errors.
 */
type ErrorLike = {
  readonly code?: unknown;
  readonly message: string;
};

/**
 * A type predicate which returns whether or not the provided error has a
 * similar shape to one returned by the Firebase SDK or custom application
 * errors.
 */
function isErrorLike(error: unknown): error is ErrorLike {
  if (typeof error !== "object" || error === null) return false;

  // Ensure that the "message" field is present and a string.
  if (
    !("message" in error) ||
    typeof (error as { message: unknown }).message !== "string"
  ) {
    return false;
  }

  return true;
}

/**
 * A mapping of Firebase error codes to custom error messages.
 */
// prettier-ignore
const firebaseErrorMap = new Map<string /* code */, string /* message */>([
  [
    'auth/cancelled-popup-request',
    ''
  ],
  [
    'auth/user-not-found',
    'Email or password is invalid.'
  ],
  [
    'auth/email-already-in-use',
    'This email already exists.'
  ],
  [
    'auth/invalid-email',
    'Email is invalid.'
  ],
  [
    'auth/invalid-api-key',
    'Something went wrong. Please try again later..'
  ],
  [
    'auth/unauthorized-domain',
    'Something went wrong. Please try again later.'
  ],
  [
    'auth/network-request-failed',
    'Something went wrong. Please try again later.'
  ],
  [
    'auth/user-cancelled',
    ''
  ],
  [
    'auth/web-storage-unsupported',
    'This browser is not supported.'
  ],
  [
    'auth/too-many-requests',
    'There have been too many requests. Please try again later.'
  ],
  [
    'auth/invalid-action-code',
    'The OTP is invalid or expired'
  ],
  [
    'auth/wrong-password',
    'Your current password is incorrect.'
  ],
]);

/**
 * Displays an Ant Design error message using the provided error.
 *
 * If the error code matches a predefined error, the predefined error message is
 * used rather than the one in the error.
 */
export function handleError(addAlert: AddAlertFn, error: unknown): void {
  if (isErrorLike(error)) {
    const content =
      typeof error.code === "string"
        ? firebaseErrorMap.get(error.code) || error.message
        : error.message;
    addAlert({ level: "error", message: content });
  } else {
    addAlert({ level: "error", message: "An unknown error has occurred." });
  }
}
