import { clsx } from "clsx";
import { type PropsWithoutRef, forwardRef } from "react";

import { Label, LabelProps } from "../Label";

export type FieldLabelProps = LabelProps;

/**
 * A `<label />` element with styles for the _disabled_ and _error_ states.
 *
 * Additionally, it is suitable for use as a labeling element to input elements,
 * with the exception of checkboxes. Checkboxes are typically rendered with the
 * label to the right.
 *
 * This component adds a margin between the label and its children. It also
 * makes the text bolder.
 */
export const FieldLabel = forwardRef<
  HTMLLabelElement,
  PropsWithoutRef<FieldLabelProps>
>(function FieldLabel({ className: classNameProp, ...rest }, ref) {
  const className = clsx("grid gap-2 font-semibold", classNameProp);

  // Disable the accessibility ESLint rule. It is covered as a custom
  // component setting in the ESLint config.
  // eslint-disable-next-line jsx-a11y/label-has-associated-control
  return <Label className={className} ref={ref} {...rest} />;
});
