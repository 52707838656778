import { type PropsWithoutRef, type RefAttributes, forwardRef } from "react";

import { useLabelClassName } from "./useLabelClassName";

export type LabelProps = PropsWithoutRef<JSX.IntrinsicElements["label"]> &
  RefAttributes<HTMLLabelElement> & {
    /**
     * Whether or not the label should be rendered with _disabled_ styling.
     *
     * @default false
     */
    readonly disabled?: boolean | undefined;

    /**
     * Whether or not the label should be rendered with _error_ styling.
     *
     * @default false
     */
    readonly error?: boolean | undefined;
  };

/**
 * A `<label />` element with styles for the _disabled_ and _error_ states.
 */
export const Label = forwardRef<HTMLLabelElement, PropsWithoutRef<LabelProps>>(
  function Label({ className: classNameProp, disabled, error, ...rest }, ref) {
    const className = useLabelClassName(
      disabled ?? false,
      error ?? false,
      classNameProp,
    );

    // Disable the accessibility ESLint rule. It is covered as a custom
    // component setting in the ESLint config.
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    return <label className={className} ref={ref} {...rest} />;
  },
);
