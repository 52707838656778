import { type PropsWithoutRef, type RefAttributes, forwardRef } from "react";

import { useInputClassName } from "./useInputClassName";

export type InputProps = PropsWithoutRef<
  Omit<JSX.IntrinsicElements["input"], "type">
> &
  RefAttributes<HTMLInputElement> & {
    /**
     * Whether the input is in an error state.
     *
     * @default false
     */
    readonly error?: boolean | undefined;

    /**
     * The type of the input.
     *
     * The relevant TailwindCSS CSS class will be applied.
     */
    readonly type:
      | "checkbox"
      | "date"
      | "datetime-local"
      | "email"
      | "month"
      | "number"
      | "password"
      | "radio"
      | "search"
      | "tel"
      | "text"
      | "time"
      | "url"
      | "week";
  };

export const Input = forwardRef<HTMLInputElement, PropsWithoutRef<InputProps>>(
  function Input(
    { className: classNameProp, disabled, error, type, ...rest },
    ref,
  ) {
    const className = useInputClassName({
      classNameProp,
      disabled,
      error,
      tailwindFormClassName:
        type === "checkbox"
          ? "form-checkbox"
          : type === "radio"
          ? "form-radio"
          : "form-input",
    });

    return (
      <input
        className={className}
        disabled={disabled}
        ref={ref}
        type={type}
        {...rest}
      />
    );
  },
);
