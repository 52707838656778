import {
  useButtonBaseClassName,
  UseButtonBaseClassNameOptions,
} from "../ButtonBase";

export type UseButtonClassNameOptions = Pick<
  UseButtonBaseClassNameOptions,
  "classNameProp" | "disabled"
>;

/**
 * Returns the composed CSS class for a styled button.
 */
export function useButtonClassName({
  classNameProp,
  disabled,
}: UseButtonClassNameOptions) {
  return useButtonBaseClassName({
    backgroundColorClassName: "bg-snap-blue",
    backgroundColorDisabledClassName: "bg-snap-blue/70",
    classNameProp,
    disabled,
    textColorClassName: "text-white",
  });
}
