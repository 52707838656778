import { clsx } from "clsx";

type TailwindFormClassName =
  | "form-checkbox"
  | "form-input"
  | "form-multiselect"
  | "form-radio"
  | "form-select"
  | "form-textarea";

type UseInputClassNameOptions = {
  /**
   * The Tailwind CSS form class to apply as the base CSS class.
   */
  tailwindFormClassName: TailwindFormClassName;

  /**
   * Whether or not the input is disabled.
   */
  disabled: boolean | undefined;

  /**
   * Whether or not the input is in an error state.
   */
  error: boolean | undefined;

  /**
   * The classname supplied by the input component consumer.
   */
  classNameProp: string | undefined;
};

/**
 * Composes the CSS class to apply to the input element.
 *
 * It is based on a combination of the Tailwind CSS form class, branding
 * overrides, input state (disabled/error), and the consumer provided className
 * prop.
 */
export function useInputClassName({
  classNameProp,
  disabled,
  error: errorProp,
  tailwindFormClassName,
}: UseInputClassNameOptions) {
  /**
   * Whether or not we need to render the input in its normal state, i.e., not
   * disabled and not in an error state.
   */
  const normal = !disabled && !errorProp;

  /**
   * Whether or not we need to render the input in its error state. This only
   * takes effect if the input is not disabled.
   */
  const error = !disabled && errorProp;

  return clsx(
    // Apply the built-in Tailwind CSS form class. This is provided by the
    // Tailwind CSS form plugin.
    tailwindFormClassName,

    // Apply the standard font.
    "font-normal font-montserrat",

    // Give an explicit height to all input types, with the exception of
    // checkboxes and radios.
    tailwindFormClassName !== "form-checkbox" &&
      tailwindFormClassName !== "form-radio" &&
      "h-12",

    // Increase the dimensions of checkboxes.
    tailwindFormClassName === "form-checkbox" && "w-5 h-5",

    // Round the corners of all input types, with the exception of radios which
    // already have defined rounding.
    tailwindFormClassName !== "form-radio" && "rounded-sm",

    // Apply disabled styling.
    disabled && "bg-snap-gainsboro border-snap-shades-of-silver",

    // Apply disabled styling to checkboxes and radios. They make use of the
    // CSS "currentColor" property value to apply background colors and borders,
    // so we need to set the color.
    disabled &&
      (tailwindFormClassName === "form-checkbox" ||
        tailwindFormClassName === "form-radio") &&
      "text-snap-sonic-silver",

    normal &&
      "bg-snap-alabaster border-snap-shades-of-silver focus:border-snap-blue focus:ring-snap-blue",

    error &&
      "bg-snap-alabaster border-snap-red/70 focus:border-snap-red focus:ring-snap-red",

    error &&
      (tailwindFormClassName === "form-checkbox" ||
        tailwindFormClassName === "form-radio") &&
      "text-snap-red",

    classNameProp,
  );
}
