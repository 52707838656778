import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { object, SchemaOf, string } from "yup";

import { useAddAlert } from "../alert";
import {
  Button,
  Divider,
  InputField,
  LayoutAuthentication,
  SocialSignInButton,
  TextLink,
} from "../components";
import { getSessionRoute } from "../session";
import { handleError, regex, validation } from "../utils";

export type SignInFormValues = {
  readonly email: string;
  readonly password: string;
};

export type SignInPageContentsProps = {
  readonly onSignInWithFacebookClick: () => Promise<void>;
  readonly onSignInWithGoogleClick: () => Promise<void>;
  readonly onSubmit: SubmitHandler<SignInFormValues>;
};

const validationSchema: SchemaOf<SignInFormValues> = object().shape({
  email: string()
    .required(validation.email.required)
    .email(validation.email.invalid),
  password: string()
    .required(validation.password.required)
    .matches(regex.password, {
      message: validation.password.invalidPwdRegex,
    }),
});

export function SignInPageContents({
  onSignInWithFacebookClick,
  onSignInWithGoogleClick,
  onSubmit,
}: SignInPageContentsProps) {
  const {
    formState: { errors, isSubmitting },
    handleSubmit: createSubmitHandler,
    register,
  } = useForm<SignInFormValues>({
    defaultValues: {
      email: "",
      password: "",
    },
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });
  const addAlert = useAddAlert();

  const handleSubmit = createSubmitHandler(onSubmit);

  const handleSignInWithFacebookClick = async () => {
    try {
      await onSignInWithFacebookClick();
    } catch (error) {
      handleError(addAlert, error);
    }
  };

  const handleSignInWithGoogleClick = async () => {
    try {
      await onSignInWithGoogleClick();
    } catch (error) {
      handleError(addAlert, error);
    }
  };

  return (
    <LayoutAuthentication
      authenticationStatus="unauthenticated"
      headerImage="peach"
      headerTitle="Welcome Back!"
      headerTitleMobile="Welcome"
    >
      <div className="flex flex-col items-center">
        <h1 className="mb-6 md:mb-12 md:text-4xl font-bold">Sign In</h1>

        <div className="mx-auto w-full max-w-sm">
          <form className="space-y-2" onSubmit={handleSubmit}>
            <InputField
              autoComplete="email"
              disabled={isSubmitting}
              error={errors.email?.message}
              label="Email Address*"
              placeholder="example@gmail.com"
              type="email"
              {...register("email")}
            />

            <div>
              <InputField
                autoComplete="current-password"
                disabled={isSubmitting}
                error={errors.password?.message}
                label="Password*"
                placeholder="*********"
                type="password"
                {...register("password")}
              />
              <TextLink
                className="block"
                disabled={isSubmitting}
                to={getSessionRoute("forgotPassword")}
                type="linkInternal"
              >
                Forgot password?
              </TextLink>
            </div>

            <div className="flex flex-col pt-8">
              <Button disabled={isSubmitting} type="submit">
                SIGN IN
              </Button>
            </div>

            <div className="flex flex-col pt-8">
              <section>
                <p>Not registered yet?</p>
                <TextLink
                  className="block"
                  disabled={isSubmitting}
                  to={getSessionRoute("signUp")}
                  type="linkInternal"
                >
                  Create new account
                </TextLink>
              </section>

              <Divider>OR</Divider>

              <div className="flex flex-col pb-4 space-y-4">
                <SocialSignInButton
                  disabled={isSubmitting}
                  onClick={handleSignInWithGoogleClick}
                  provider="google"
                >
                  Sign in with Google
                </SocialSignInButton>
                {/* <SocialSignInButton
                  disabled={isSubmitting}
                  onClick={handleSignInWithFacebookClick}
                  provider="facebook"
                >
                  Sign in with Facebook
                </SocialSignInButton> */}
              </div>
            </div>
          </form>
        </div>
      </div>
    </LayoutAuthentication>
  );
}
