import { clsx } from "clsx";
import { MdAccountCircle, MdMenu } from "react-icons/md";

import { useLayoutProfileMenu } from "../LayoutProfileMenu";

export type LayoutAppBarSession =
  | {
      /**
       * A discriminator used to determine which additional authentication
       * related props will be required.
       */
      readonly authenticationStatus: "authenticatedUserRecordMissing";

      /**
       * Called when the user clicks the sign out button on the profile menu.
       *
       * Required when `authenticationStatus` is
       * `authenticatedUserRecordMissing` or `authenticatedUserRecordPreset`.
       */
      readonly onSignOutClick: () => void;
    }
  | {
      /**
       * A discriminator used to determine which additional authentication
       * related props will be required.
       */
      readonly authenticationStatus: "authenticatedUserRecordPreset";

      /**
       * Called when the user clicks the sign out button on the profile menu.
       *
       * Required when `authenticationStatus` is
       * `authenticatedUserRecordMissing` or `authenticatedUserRecordPreset`.
       */
      readonly onSignOutClick: () => void;

      /**
       * The user's full name.
       *
       * Required when `authenticationStatus` is
       * `authenticatedUserRecordPreset`.
       */
      readonly userFullName: string;
    }
  | {
      /**
       * A discriminator used to determine which additional authentication
       * related props will be required.
       */
      readonly authenticationStatus: "unauthenticated";
    };

export type LayoutAppBarProps = LayoutAppBarSession & {
  readonly className?: string;

  /**
   * If provided, a hamburger button is rendered on the left on mobile. Called
   * when clicked.
   */
  readonly onHamburgerClick?: () => void;
};

const headerLinkBaseClassName =
  "flex justify-center items-center p-3 lg:text-lg hover:text-snap-blue transition-colors outline-none focus:ring-2";

const headerLinkDrawerButton = clsx(headerLinkBaseClassName, "md:hidden -ml-3");

const headerLinkIconClassName =
  "flex-shrink-0 w-6 h-6 md:w-14 md:h-14 fill-current";

const headerLinkProfileButton = clsx(headerLinkBaseClassName, "-mr-3 min-w-0");

export function LayoutAppBar({
  className: classNameProp,
  onHamburgerClick,
  ...session
}: LayoutAppBarProps) {
  const { handleProfileButtonClick, profileButtonRef, profileMenuElement } =
    useLayoutProfileMenu({
      onSignOutClick:
        session.authenticationStatus === "unauthenticated"
          ? // eslint-disable-next-line @typescript-eslint/no-empty-function
            () => {}
          : session.onSignOutClick,
    });

  const className = clsx(
    "flex items-center px-4 md:px-8 lg:px-[3.5rem] w-full h-14 md:h-full bg-white shadow-md md:shadow-none z-10",
    classNameProp,
  );

  return (
    <header className={className}>
      {onHamburgerClick && (
        <button
          className={headerLinkDrawerButton}
          onClick={onHamburgerClick}
          type="button"
        >
          <span className="sr-only">Open Drawer</span>
          <MdMenu className={headerLinkIconClassName} />
        </button>
      )}

      <div className="flex-1" />

      {session.authenticationStatus !== "unauthenticated" && (
        <button
          className={headerLinkProfileButton}
          id="profile-button"
          onClick={handleProfileButtonClick}
          ref={profileButtonRef}
          type="button"
        >
          <span className="sr-only">Open Profile Dialog</span>
          {session.authenticationStatus === "authenticatedUserRecordPreset" && (
            <span className="hidden sm:block not-sr-only mr-2 overflow-hidden whitespace-nowrap text-ellipsis">
              {session.userFullName}
            </span>
          )}
          <MdAccountCircle className={headerLinkIconClassName} />
        </button>
      )}

      {/* Profile Menu */}
      {session.authenticationStatus !== "unauthenticated" && profileMenuElement}
    </header>
  );
}
